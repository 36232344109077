import React from 'react';

const defaultState = {
  showHeader: true,
};

const ScrollContext = React.createContext(defaultState);

class ScrollProvider extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showHeader: true,
      lastScrollPos: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    typeof window !== 'undefined' && 
      window.addEventListener('scroll', e => this.handleScroll(e));
  }

  handleScroll(e) {
    if(this.state.lastScrollPos < e.currentTarget.scrollY) {
      this.setState({ showHeader: false });
    } else if(this.state.lastScrollPos > e.currentTarget.scrollY) {
      this.setState({ showHeader: true });
    }

    this.setState({ lastScrollPos: e.currentTarget.scrollY });
  }

  render() {
    const { children } = this.props;
    const { showHeader } = this.state;
    return (
      <ScrollContext.Provider
        value={{
          showHeader,
        }}
      >
        {children}
      </ScrollContext.Provider>
    );
  }
}

export default ScrollContext;
export { ScrollProvider };
