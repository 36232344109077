import React from 'react';
import { ScrollProvider } from './src/context/ScrollContext';

const injectMathJaxScript = () => {
  const s = document.createElement('script');

  s.type = 'text/javascript';
  s.src = 'https://cdn.bootcss.com/mathjax/2.7.4/latest.js?config=TeX-AMS_SVG';
  document.getElementsByTagName('head')[0].appendChild(s);
};

let injectedtMathJaxScript = false;


export const onRouteUpdate = () => {
  if (!injectedtMathJaxScript) {
    injectMathJaxScript();
    injectedtMathJaxScript = true;
  }
  if (
    typeof MathJax !== 'undefined' &&
    window.MathJax.Hub &&
    typeof window.MathJax.Hub.Typeset === 'function'
  ) {
    window.MathJax.Hub.Typeset();
  }
};

export const wrapRootElement = ({ element }) => (
  <ScrollProvider>{element}</ScrollProvider>
);